<template>
  <div class="blue-line" />
  <div id="foot" class="foot">
    <main>
      <div class="left">
        <p class="wide">联系我们</p>
        <p>客服电话： 023-65432187  9:00-18:00</p>
        <p>商务合作：service@lxhua.com.cn</p>

        <p class="pt20">北京：北京市朝阳区广顺北大街33号福码大厦</p>
        <p>上海：上海市静安区西藏北路525号</p>
        <p>重庆：重庆市渝中区接圣街8号重庆来福士办公楼A座</p>
        <p>成都：成都市高新区锦城大道666号奥克斯广场</p>

        <p class="pt20">领创集团官网： https://www.advancegroup.com</p>
        <p>新加坡官网： https://atome.sg</p>
        <p>马来西亚官网： https://atome.my</p>
        <p>香港官网： https://atome.hk</p>
        <p>印尼官网： https://atome.id</p>
        <p>领英主页： https://www.linkedin.com/company/atomesg</p>

        <div class="flex-sp">
          <div class="zi-xun">
            <p class="wide">更多资讯</p>
            <div class="flex-sp">
              <div class="flex-cen">
                <img src="../images/img_gongzonghao_yonghu.png">
                <span class="pt10">立享花公众号</span>
              </div>
              <div class="flex-cen">
                <img src="../images/img_gongzonghao_shanghu.png">
                <span class="pt10">立享商户公众号</span>
              </div>
            </div>
          </div>
          <div class="xia-zai">
            <p class="wide">扫描二维码下载App立即体验</p>
            <div class="flex-sp">
              <div class="flex-cen">
                <img src="../images/img_ios.png">
                <span class="pt10">iOS</span>
              </div>
              <div class="flex-cen">
                <img src="../images/img_andriod.png">
                <span class="pt10">Andriod</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <p class="kai-shi">立即开始</p>
        <p class="zeng-zhang">让立享花帮你实现增长</p>
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="rules"
          label-width="120px"
          class="ruleForm"
          :size="formSize"
        >
          <el-form-item label="姓名" prop="userName">
            <el-input v-model="ruleForm.userName" />
          </el-form-item>
          <el-form-item label="联系电话" prop="contactMobile">
            <el-input v-model="ruleForm.contactMobile" />
          </el-form-item>
          <el-form-item label="邮箱地址" prop="email">
            <el-input v-model="ruleForm.email" />
          </el-form-item>
          <el-form-item label="行业" prop="category">
            <el-input v-model="ruleForm.category" />
          </el-form-item>
          <el-form-item label="公司" prop="company">
            <el-input v-model="ruleForm.company" />
          </el-form-item>
          <el-form-item label="职位" prop="position">
            <el-input v-model="ruleForm.position" />
          </el-form-item>
          <el-form-item label="公司所在地" prop="companyAddress">
            <el-input v-model="ruleForm.companyAddress" />
          </el-form-item>
        </el-form>
        <el-button
          type="primary"
          class="login-btn"
          @click="submitForm(ruleFormRef)"
        >开启增长</el-button>
        <p class="lian-xi">填写联系方式，立享花工作人员将与您联系</p>
      </div>
    </main>
    <div class="bei-an">备案/许可证编号: <a class="blue" target="_blank" href="https://beian.miit.gov.cn">渝ICP备2021007282号</a><img src="../images/备案图标.png" class="m20"><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010302003903" class="blue">渝公网安备 50010302003903号</a></div>
  </div>
</template>

<script setup>
import { reactive, ref } from '@vue/reactivity'
import { getCurrentInstance } from 'vue'
import axios from 'axios'
const internalInstance = getCurrentInstance()
const ruleForm = reactive({
  userName: '',
  contactMobile: '',
  email: '',
  category: '',
  company: '',
  position: '',
  companyAddress: ''
})
const ruleFormRef = ref()
const rules = reactive({
  userName: [
    {
      required: true,
      message: '请输入姓名',
      trigger: 'blur'
    }
  ],
  contactMobile: [
    {
      required: true,
      message: '请输入联系电话',
      trigger: 'blur'
    }
  ],
  category: [
    {
      required: true,
      message: '请输入行业',
      trigger: 'blur'
    }
  ],
  company: [
    {
      required: true,
      message: '请输入公司',
      trigger: 'blur'
    }
  ],
  companyAddress: [
    {
      required: true,
      message: '请输入公司所在地',
      trigger: 'blur'
    }
  ]
})
const submitForm = (formEl) => {
  console.log(formEl)
  if (!formEl) return
  formEl.validate(async(valid) => {
    if (valid) {
      const { email } = ruleForm
      if ((/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(email) && email) || !email) {
        const response = await axios({
          method: 'POST',
          url: '/api/boss/v1/offical/add/userInfo',
          data: {
            ...ruleForm
          }
        })
        if (response.data.code === 200) {
          internalInstance.appContext.config.globalProperties.$message({
            message: '成功',
            type: 'success'
          })
          ruleFormRef.value.resetFields()
        } else {
          internalInstance.appContext.config.globalProperties.$message(response.data.message)
        }
      } else {
        internalInstance.appContext.config.globalProperties.$message('请输入正确的邮箱格式')
      }
    } else {
      return false
    }
  })
}
</script>

<style lang='less' scoped>
.blue-line{
  width: 100%;
  height: 10px;
  background: #4481FD;
}
.blue{
  color:#6085CF;
  cursor: pointer;
}
.m20{
  margin:0 5px 0 20px;
}
.el-form-item{
  margin-bottom:18px;
}
p{
  margin:10px 0;
}
.pt10{
  padding-top:10px;
}
.flex-sp{
  display:flex;
  justify-content:space-between;
}
.flex-cen{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.foot{
  width: 100%;
  background: #3D3D3D;
  font-size: 12px;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  font-weight: 400;
  color: #FFFFFF;
  main{
    width:1032px;
    margin:auto;
    display: flex;
    justify-content:space-between;
    img{
      width:100px;
      height:100px;
    }
    .wide{
      font-size: 14px;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      color: #FFFFFF;
    }
    .pt20{
      padding-top:20px;
    }
    .left{
      width: 544px;
      padding:44px 0 29px;
      .zi-xun,.xia-zai{
      width:220px;
      }
    }
    .right{
      margin-top:40px;
      width: 382px;
      // height: 490px;
      background: #FFFFFF;
      border-radius: 10px;
      padding:24px 0 12px 0;
      .zeng-zhang{
        text-align:center;
        font-size: 12px;
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
        margin:0;
        padding:3px 0 20px;

      }
      .kai-shi{
        text-align:center;
        font-size: 18px;
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        font-weight: 400;
        color: #333333;
        line-height: 25px;
        margin:0;
      }
      .ruleForm{
        padding-right:56px;
      }
      .login-btn{
        display: block;
        width:210px;
        height: 40px;
        background: #4481FD !important;
        border-radius: 6px;
        margin:20px auto 0;
      }
      .lian-xi{
        font-size: 10px;
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        font-weight: 400;
        color: #999999;
        text-align:center;
      }
    }
  }
  .bei-an{
    display: block;
    text-align: center;
    padding-bottom:20px;
    font-size: 12px;
    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
    font-weight: 400;
    color: #888888;
    line-height: 17px;
    margin-top:50px;
    display:flex;
    align-items:center;
    justify-content:center;
  }
}
</style>
