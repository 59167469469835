import { createApp } from 'vue'
import '@/assets/flexible'
import App from './App.vue'
import router from './router'
import 'element-plus/dist/index.css'
import 'wowjs/css/libs/animate.css'
import '@/assets/css/index.css'
import '@/assets/protocol.less'
import ElementPlus from 'element-plus'
const app = createApp(App)
app.config.globalProperties.$message = ElementPlus.ElMessage
app.use(router)
app.use(ElementPlus)
app.mount('#app')
