import {
  createRouter,
  createWebHistory
} from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: [{
    path: '/',
    redirect: '/index'
  }, {
    path: '/index',
    name: 'index',
    component: () => import('./views/home.vue'),
    meta: {
      title: '首页'
    }
  }, {
    path: '/user',
    name: 'user',
    component: () => import('./views/user.vue'),
    meta: {
      title: '用户'
    }
  }, {
    path: '/business',
    name: 'business',
    component: () => import('./views/business.vue'),
    meta: {
      title: '商户'
    }
  }],
  scrollBehavior(to, from, savedPosition) {
    // 滚动到顶部
    window.scrollTo(0, 0)
    return { x: 0, y: 0 }
  }
})
export default router
