<template>
  <div :class="['header',{'opa1':scrollTop>0}]">
    <div class="width1200">
      <img src="../images/logo.png" class="logo" @click="jump('/')">
      <div class="right">
        <div class="user">
          <span :class="['shou-ye',{'blue':routeName==='index'}]" @click="jump('/index')">首页</span>
          <img src="../images/icon_yonghu.png">
          <span :class="['yong-hu',{'blue':routeName==='user'}]" @click="jump('/user')">我是用户</span>
          <el-popover placement="bottom" :width="400" trigger="hover">
            <template #reference>
              <span class="ti-yan">立即体验</span>
            </template>
            <div class="xia-zai">
              <p class="wide">下载App</p>
              <div class="flex-sp">
                <div class="flex-cen">
                  <img src="../images/ios@2x.png">
                  <span class="pt10">iOS</span>
                </div>
                <div class="flex-cen">
                  <img src="../images/andriod@2x.png">
                  <span class="pt10">Andriod版本</span>
                </div>
              </div>
            </div>
          </el-popover>
        </div>
        <div class="business">
          <img src="../images/icon_shanghu.png">
          <span :class="['shang-jia',{'blue':routeName==='business'}]" @click="jump('/business')">我是商家</span>
          <span class="deng-lu" @click="jumpOutside">立即登录</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router'
import { onMounted, ref, watch } from 'vue'
const router = useRouter()
const route = useRoute()
// 跳转内部路由
const jump = (url) => {
  router.push(url)
  // window.location = `${window.location.origin}${url}`
}
const routeName = ref()
const scrollTop = ref(0)
onMounted(() => {
  window.addEventListener('scroll', () => {
    scrollTop.value = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
  })
})
watch(() => route.name, () => { routeName.value = route.name })
// 当前地址
const jumpOutside = () => {
  window.location.href = 'https://merchant.lxhua.com.cn'
}
</script>

<style lang='less' scoped>
.blue{
  color:#4481FD;
}
span{
  font-size: 16px;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  font-weight: 400;
  color: #191919;
}
.xia-zai{
  text-align:center;
  img{
    width:180px;
    height:180px;
  }
}
.wide{
  font-size: 18px;
  font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
  font-weight: 500;
  color: #191919;
}
.flex-sp{
  display:flex;
  justify-content:space-between;
}
.flex-cen{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.opa1{
  opacity: 1 !important;
}
.width1200{
  margin:auto;
  width: 1200px;
  height: 90px;
  display: flex;
  justify-content:space-between;
  align-items: center;
}
.header{
  position: sticky;
  z-index:100;
  top:0;
  width: 100%;
  background: #FFFFFF;
  opacity: 0.85;
  .logo{
    width:160px;
    height:45px;
    cursor: pointer;
  }
  .right{
    display: flex;
    align-items: center;
    justify-content:space-between;
    img{
      width:32px;
      height:32px;
    }
    .user{
      display: flex;
      align-items: center;
      .yong-hu{
        padding-left:7px;
        cursor:pointer;
        cursor:pointer;
      }
      .shou-ye{
        margin-right:22px;
        text-align:left;
        width:60px;
        display:inline-block;
        border-right:1px solid #666666;
        cursor:pointer;
      }
      .ti-yan{
        margin-left:22px;
        width:93px;
        display:inline-block;
        border-right:1px solid #666666;
        cursor:pointer;
      }
    }
    .shou-ye:hover{
      color:#4481FD !important;
    }
    .deng-lu:hover{
      color:#4481FD !important;
    }
    .shang-jia:hover{
      color:#4481FD !important;
    }
    .ti-yan:hover{
      color:#4481FD !important;
    }
    .yong-hu:hover{
      color:#4481FD !important;
    }
    .business{
      display: flex;
      align-items: center;
      padding-left:32px;
      .shang-jia{
        padding-left:7px;
        cursor:pointer;
      }
      .deng-lu{
        padding-left:22px;
        cursor:pointer;
      }
    }
  }
}
</style>
